import { Fragment } from 'react';
import { Helmet } from 'core/libs/helmet';
import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import TagTopics from 'core/components/TagTopics';
import ColumnLayout from 'core/components/ColumnLayout';

import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import RubricTopics from 'site/components/RubricTopics';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import footerFetcher from 'site/fetchers/footer';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';


const TagPage = ({ tag, topics: tagTopics, footerContent }) => {
  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <TagTopics
            tag={tag}
            topics={tagTopics}
            limit={RUBRICS_PAGE_LIMIT}
            titleComponent={ListHeader}
          >
            {props => {
              // eslint-disable-next-line react/prop-types
              const { title, titleMeta, descriptionMeta, topics } = props;

              return (
                <Fragment>
                  <Helmet>
                    <title>{titleMeta || `#${title}: новости, кейсы, статьи`}</title>
                    <meta name='description' content={descriptionMeta || `Новости, кейсы, статьи по тегу #${title} в интернет-журнале Секрет фирмы.`} />
                  </Helmet>
                  <RubricTopics topics={topics} />
                </Fragment>
              );
            }}
          </TagTopics>
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
};

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  footerContent: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_2: PropTypes.string.isRequired,
    }),
  }),
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: filterRequiredParams([CardVertical1, CardHorizontal], 'include'),
    fields: filterRequiredParams([CardVertical1, CardHorizontal], 'fields'),
  }),
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(TagPage);
