import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import withPageHocs from 'core/components/withPageHocs';

import { getPage } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';


const title = 'Последние новости России и мира — все статьи и новости';
const description = 'Читайте свежие новости России на портале Секрет фирмы. Издание о бизнесе, экономике, финансах и высоких технологиях, которые обеспечивают развитие экономики и бизнеса в России и в мире';

const include = filterRequiredParams([CardVertical1, CardHorizontal], 'include');
const fields = filterRequiredParams([CardVertical1, CardHorizontal], 'fields');

function NewsPage(props) {
  const {
    rawData,
    footerContent,
    meta,
  } = props;

  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <ListHeader>Новости</ListHeader>
          <ListPage
            rawData={rawData}
            title={title}
            description={description}
            meta={meta}
            limit={RUBRICS_PAGE_LIMIT}
          >
            {({ content }) => <RubricTopics topics={content} />}
          </ListPage>
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

NewsPage.propTypes = {
  rawData: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  footerContent: PropTypes.array,
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
};

const dataProvider = resolve({
  rawData(props) {
    const {
      bebopApi,
      topics,
      location: { search },
      renderOffline,
    } = props;

    const offset = (getPage(search) - 1) * RUBRICS_PAGE_LIMIT;

    return topics || bebopApi
      .getTopics({
        limit: RUBRICS_PAGE_LIMIT,
        offset,
        include,
        fields,
        topic_type: 'news',
        with_filtered_count: 1,
      })
      .catch(renderOffline);
  },

  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(NewsPage);
