import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import RecommendationWidget from 'core/components/RecommendationWidget';

import ListHeader from 'site/components/ListHeader';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '0be61d92c1c842948532f10d294ffd34' : '39985572aff04377bfbb8f555c69b391'}
      title='Популярное'
      headerComponent={bindProps({ level: 2, bottomIndent: 25 })(ListHeader)}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
