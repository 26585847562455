import { withRouter } from 'core/libs/router';
import PropTypes from 'prop-types';

import Socializator from 'core/components/Socializator';
import { SHARE } from 'site/constants';
import Skeleton from 'core/components/Socializator/Skeleton';

import { hasPreviewToken } from 'core/utils/url-helper';


// TODO: Настройки сделать как были на старом сайте, особенно utm
const settings = {
  'vkontakte': {
    'utm': 'utm_source=vksharing&utm_medium=social',
  },
  'odnoklassniki': {
    'utm': 'utm_source=oksharing&utm_medium=social',
  },
  'twitter': {
    'utm': 'utm_source=twsharing&utm_medium=social',
  },
  'telegram': {
    'utm': 'utm_source=tgsharing&utm_medium=social',
  },
  'whatsapp': {
    'utm': 'utm_source=washaring&utm_medium=social',
  },
};

function SocialShare({ location: { search } }) {
  if (hasPreviewToken(search)) return <Skeleton />;

  return <Socializator {...settings} buttons={SHARE} />;
}

SocialShare.propTypes = {
  /** @ignore */
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

export default withRouter(SocialShare);
