import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import resolve from 'core/resolver/resolve';
import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import ColumnLayout from 'core/components/ColumnLayout';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';

import withPageHocs from 'core/components/withPageHocs';

import InnerSideColumn from 'site/components/InnerSideColumn';
import { Indent, PageIndent } from 'core/components/Wrappers';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';

import TopicHeader from 'site/pages/topic/TopicHeader';


function TopicPage({ content, footerContent }) {
  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <Topic content={content}>
            {topic => {
              const {
                id,
                attributes: {
                  link,
                  is_comments_disabled: isCommentsDisabled,
                },
              } = topic;

              return (
                <Fragment>
                  <TopicHeader
                    content={topic}
                    hideCommentsButton
                    hideMedia
                  />
                  <Indent bottom= {20} />
                  <Link to={link} >
                    <Button size='small'>Вернуться к статье</Button>
                  </Link>
                  <Comments xid={id} isCommentsDisabled={isCommentsDisabled} />
                </Fragment>
              );
            }}
          </Topic>
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

TopicPage.propTypes = {
  content: PropTypes.object,
  footerContent: PropTypes.array,
};

const additionalDataProvider = resolve({
  footerContent: footerFetcher(),
});

const composedProvider = compose(topicProvider, additionalDataProvider);

export default withPageHocs(composedProvider)(TopicPage);
