import { Fragment } from 'react';
import PropTypes from 'prop-types';

import coreMarkdown from 'core/components/TopicContent/blocks/markdown';
import CardTopicBg from 'site/components/CardTopicBg';


export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
    parentProps: {
      topic,
    },
  } = props;

  const {
    attributes: {
      topic_type: topicType,
    },
  } = topic.content;

  const isCard = topicType === 'card';
  const CoreWidgetWrapper = isCard ? CardTopicBg : Fragment;

  return (
    <CoreWidgetWrapper>
      {coreMarkdown(block, parentProps, ctx)}
    </CoreWidgetWrapper>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
