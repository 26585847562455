import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import Button from 'core/components/Button';

import DotsBurger from 'site/icons/DotsBurger.svg';
import ArrowRight from 'site/icons/ArrowRight';
import ArrowLeft from 'site/icons/ArrowLeft';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';

import StoryTopicCard from 'site/cards/StoryTopicCard';
import { CardVertical1M } from 'site/cards/CardVertical';
import CompareCard from 'site/cards/CompareCard';
import Logo from 'site/components/Logo';

import { NegativeMobile, TopicTextWrap } from 'site/components/Wrappers';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
} from 'site/constants';

import planet from 'site/images/planet.png';
import aliens from 'site/images/aliens.png';

import fonts from './fonts';

const icons = {
  logo: Logo,
  dotsBurger: DotsBurger,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  Video,
  Photo,
};

export default function baseTheme(atoms) {
  const layout = {
    sideColumnIndent: '30px',
    contentColumnMaxWidth: '870px',
    boxShadow: 'none',
    indents: {
      top: 20,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const texts = {
    lead: {
      font: `400 20px/29px ${fonts.display}`,
      color: atoms.colors.grey4,
    },

    body: {
      font: `18px/27px ${fonts.text}`,
    },

    // h1 в топике
    title1: {
      font: `800 42px/52px ${fonts.display}`,
    },

    // заголовки второго уровня в топике (не подзаголовок)
    title2: {
      font: `700 32px/40px ${fonts.display}`,
    },

    // большинство тайтлов карточек
    title3: {
      font: `700 24px/35px ${fonts.display}`,
    },

    title4: {
      font: `700 18px/26px ${fonts.display}`,
    },

    // маленькие карточки
    title5: {
      font: `700 18px/26px ${fonts.display}`,
    },

    // главная карточка в черном mjolnir
    title6: {
      font: `700 18px/26px ${fonts.display}`,
    },

    incut: {
      font: `bold 18px/22px ${fonts.text}`,
    },
  };

  const link = {
    primary: {
      hover: {
        decoration: 'underline',
      },
    },

    article: {
      hover: {
        decoration: 'none',
      },
    },

    secondary: {
      idle: {
        decoration: 'none',
      },
      hover: {
        decoration: 'none',
      },
      active: {
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    footer: {
      idle: {
        color: atoms.colors.hintLight,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.hintLight,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.hintLight,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    tertiary: {
      hover: {
        decoration: 'underline',
      },
    },
  };

  const scooter = {
    social: {
      margin: '0 0 0 30px',
      size: 40,
      iconSize: 18,
      idle: {
        color: atoms.colors.active1,
        background: atoms.colors.placeholder,
        border: '2px solid transparent',
      },
      hover: {
        color: atoms.colors.active1,
        background: 'transparent',
        border: `2px solid ${atoms.colors.active1}`,
      },
    },
    padding: `0 ${SIDE_INDENT}px`,
    logo: {
      width: 91,
      height: 40,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 20px/22px ${fonts.display}`,
      },
      info: {
        font: `400 16px/20px ${fonts.text}`,
      },
      link: {
        hover: {
          color: atoms.colors.hintLight,
        },
      },
    },
    gameCompare: {
      progressType: 1,
    },
  };

  const mark = {
    padding: '0 0.1em',
    background: '#F7D985',
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `700 15px/18px ${fonts.text}`,
      topSpacing: VERTICAL_INDENT,
      size: 'small',
    },
    socialMedia: {
      type: 'fancy',
    },
    links: {
      borderBottom: '1px transparent solid',
      showComma: false,
      font: `400 12px/14px ${fonts.text}`,
      marginLeft: '15px',
      interitemSpacing: 20,
      title: {
        font: `700 12px/18px ${fonts.text}`,
      },
      hover: {
        borderBottom: `1px ${atoms.colors.hintLight} solid`,
      },
    },
    block: {
      spacing: VERTICAL_INDENT,
    },
  };

  const topicTags = {
    font: topicFooter.links.font,
    showHash: false,
  };

  const button = {
    const: {
      sizes: {
        extraSmall: {
          font: `700 12px/12px ${fonts.display}`,
        },
        small: {
          font: `700 15px/18px ${fonts.display}`,
        },
        large: {
          font: `700 16px/19px ${fonts.display}`,
          width: '190px',
          height: '40px',
          padding: '10px 50px',
        },
        extraLarge: {
          font: `bold 20px/24px ${fonts.display}`,
          width: '290px',
          height: '42px',
          padding: '12px 46px',
        },
      },
      radius: '3px',
    },

    primary: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.content,
        background: color(atoms.colors.primary).alpha(0.9).string(),
        border: `1px solid ${atoms.colors.primary}`,
      },

      active: {
        color: atoms.colors.content,
        background: color(atoms.colors.primary).alpha(0.9).string(),
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: atoms.colors.content,
        background: color(atoms.colors.primary).alpha(0.9).string(),
        border: `1px solid ${atoms.colors.primary}`,
      },
    },

    primaryYellow: {
      idle: {
        color: atoms.colors.text,
        background: atoms.colors.yellow,
        border: `2px solid ${atoms.colors.yellow}`,
      },

      hover: {
        color: atoms.colors.text,
        background: color(atoms.colors.yellow).alpha(0.9).string(),
        border: `2px solid ${color(atoms.colors.yellow).alpha(0.9).string()}`,
      },

      active: {
        color: atoms.colors.text,
        background: color(atoms.colors.yellow).alpha(0.9).string(),
        border: `2px solid ${color(atoms.colors.yellow).alpha(0.9).string()}`,
      },

      disabled: {
        color: atoms.colors.text,
        background: color(atoms.colors.yellow).alpha(0.9).string(),
        border: `2px solid ${atoms.colors.yellow}`,
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.primary,
        background: 'none',
        border: `1px solid ${atoms.colors.divider}`,
      },

      hover: {
        color: atoms.colors.primary,
        background: 'none',
        border: `1px solid ${color(atoms.colors.divider).darken(0.15).string()}`,
      },

      active: {
        color: atoms.colors.primary,
        background: 'none',
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: atoms.colors.divider,
        background: 'none',
        border: `1px solid ${color(atoms.colors.divider).alpha(0.4).string()}`,
      },
    },

    secondaryYellow: {
      idle: {
        color: atoms.colors.yellow,
        background: 'none',
        border: `1px solid ${atoms.colors.yellow}`,
      },

      hover: {
        color: atoms.colors.yellow,
        background: color(atoms.colors.yellow).alpha(0.1).string(),
        border: `1px solid ${color(atoms.colors.yellow).darken(0.15).string()}`,
      },

      active: {
        color: atoms.colors.yellow,
        background: 'none',
        border: `1px solid ${atoms.colors.yellow}`,
      },

      disabled: {
        color: atoms.colors.yellow,
        background: 'none',
        border: `1px solid ${color(atoms.colors.yellow).alpha(0.4).string()}`,
      },
    },

    secondaryDark: {
      idle: {
        color: '#000',
        background: atoms.colors.layout,
        border: '1px solid #000',
      },

      hover: {
        color: '#000',
        background: atoms.colors.layout,
        border: '1px solid #000',
      },

      active: {
        color: '#000',
        background: atoms.colors.layout,
        border: '1px solid #000',
      },

      disabled: {
        color: '#000',
        background: atoms.colors.layout,
        border: '1px solid #000',
      },
    },

    tertiary: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },
    },

    ghost: {
      idle: {
        color: atoms.colors.text,
      },
      hover: {
        color: atoms.colors.hintLight,
      },
    },
  };

  const dropdown = {
    content: {
      boxShadowColor: '0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    },
  };

  const shapkaLink = {
    hover: {
      decoration: 'underline',
    },
    current: {
      color: atoms.colors.hintLight,
    },
    font: `700 14px/23px ${fonts.display}`,
    padding: '6px 15px',
  };

  const shapka = {
    link: shapkaLink,
    dropdown: {
      link: {
        ...shapkaLink,
        padding: '6px 30px 6px 15px',
      },
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.14)',
      padding: '0',
      premiumTopic: {
        link: {
          ...shapkaLink,
          padding: '6px 20px 6px 0',
        },
      },
    },
    logo: {
      width: 92,
      height: 40,
    },
    padding: '0px',
    topIndent: '5px',
    menu: {
      centered: true,
      itemDivider: `1px solid ${atoms.colors.divider}`,
    },
  };

  const errorPage = {
    imgUrl: planet,
    background: 'transparent',
    imgWidth: 355,
    title: {
      font: `bold 56px/56px ${fonts.display}`,
    },
    subtitle: {
      font: `36px/49px ${fonts.text}`,
    },
    card: CardVertical1M,
  };

  const pages = {
    error: errorPage,
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      title: {
        ...errorPage.title,
        name: 'Что-то пошло не так',
      },
      subtitle: {
        ...errorPage.subtitle,
        name: 'Мы уже знаем об этом и скоро всё исправим',
      },
      imgUrl: aliens,
      background: '',
      imgWidth: 260,
      card: null,
    },
  };

  const input = {
    basic: {
      idle: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.divider}`,
      },
      hover: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.divider}`,
      },
      focused: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.divider}`,
      },
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 20px/22px ${fonts.display}`,
      color: atoms.colors.primary,
    },
    name: {
      font: `bold 14px/21px ${fonts.nuance}`,
    },
    date: {
      font: `normal 14px/17px ${fonts.nuance}`,
    },
    comment: {
      font: `normal 16px/24px ${fonts.nuance}`,
    },
  };

  const gallery = {
    slide: {
      background: atoms.colors.primary,
    },
  };

  const drum = {
    arrow: {
      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
    },
  };

  const paginatron = {
    outerButtons: {
      innerBorder: button.secondary.idle.border,
    },
    radius: button.const.radius,
    text: {
      font: `bold 20px/24px ${fonts.display}`,
    },
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/images/mockup-2x.png'),
      common: require('site/images/mockup-1x.png'),
    },
  };

  const topicTopline = {
    font: `400 12px/14px ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.6).string(),
    rubric: {
      font: `700 8px/1 ${fonts.nuance}`,
      color: atoms.colors.text,
      padding: '3px 4px',
      background: atoms.colors.yellow,
      border: 'none',
      borderRadius: '3px',
      textTransform: 'uppercase',
      hover: {
        color: atoms.colors.text,
        background: atoms.colors.yellow,
      },
      premiumTopic: {
        color: atoms.colors.content,
      },
    },
  };

  const expert = {
    name: {
      idle: {
        font: `700 16px/20px ${fonts.text}`,
        borderBottom: '1px solid transparent',
      },
      hover: {
        color: atoms.colors.primary,
        font: `600 16px/20px ${fonts.text}`,
        borderBottom: `1px solid ${atoms.colors.primary}`,
      },
    },
    jobTitle: {
      color: atoms.colors.hint,
      font: `400 12px/14px ${fonts.text}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `700 10px/1 ${fonts.text}`,
      letterSpacing: '0.02em',
    },
    avatar: {
      borderRadius: '3px',
      width: 60,
    },
  };

  const author = {
    types: {
      expert: {
        background: 'transparent',
        color: atoms.colors.primary,
        borderRadius: '2px',
        border: `1px solid ${atoms.colors.primary}`,
        textTransform: 'none',
      },
    },
  };

  const notes = {
    background: atoms.colors.grey2,
  };

  const topicContent = {
    widgetsMaxWidth: 660,
    widgetsWithMaxWidth: null,
    widgetsWithNegativeMobileIndent: {
      'smartTeaser': true,
    },
  };

  const story = {
    slide: {
      width: 145,
      spaceBetween: 12,
    },
  };

  const storyCard = {
    card: {
      Card: StoryTopicCard,
    },
    statusLine: {
      border: `2px solid ${atoms.colors.yellow}`,
      borderRadius: '6px',
    },
  };

  const storyWidgetsBaseCard = {
    color: atoms.colors.white,
    buttonType: 'primaryYellow',

    headline: {
      font: `600 22px/1.2 ${fonts.display}`,
      textTransform: 'normal',
    },
    lead: {
      font: `400 14px/18px ${fonts.display}`,
    },
  };

  const storyWidgetsVariants = {
    color: atoms.colors.content,
    headline: {
      font: `800 26px/28px ${fonts.display}`,
      textTransform: 'none',
    },

    states: {
      idle: {
        caption: {
          background: atoms.colors.yellow,
        },
        image: {
          boxShadow: `-3px 3px 0 0 ${atoms.colors.yellow}`,
        },
      },
      hover: {
        caption: {
          background: atoms.colors.yellow,
        },
        image: {
          boxShadow: `-5px 5px 0 0 ${atoms.colors.yellow}`,
        },
      },
      checked: {
        caption: {
          background: atoms.colors.yellow,
        },
        image: {
          boxShadow: `-3px 3px 0 0 ${atoms.colors.yellow}`,
        },
      },
    },

    twoVariants: {
      caption: {
        font: `800 24px/26px ${fonts.display}`,
      },
    },
    threeVariants: {
      caption: {
        font: `800 18px/20px ${fonts.display}`,
      },
    },
  };

  const storyWidgetsRatingCard = {
    headline: {
      font: `600 28px/35px ${fonts.display}`,
    },
    percent: {
      font: `30px/40px ${fonts.display}`,
    },
  };

  const storyWidgetsHiddenPhoto = {
    states: {
      correct: {
        answerText: {
          background: atoms.colors.yellow,
        },
      },
    },

    variant: {
      hover: {
        border: `1px solid ${atoms.colors.yellow}`,
        background: atoms.colors.yellow,
      },
      checkedCorrect: {
        border: `1px solid ${atoms.colors.yellow}`,
        background: atoms.colors.yellow,
      },
    },
  };

  const reactions = {
    tiny: {
      font: `600 11px/13px ${fonts.text}`,
    },

    small: {
      font: `600 14px/16px ${fonts.text}`,
      radius: '13px',
      padding: '3px 7px',
    },

    primary: {
      idle: {
        color: atoms.colors.yellow,
        border: '1px solid transparent',
      },
      checked: {
        color: atoms.colors.yellow,
        border: `1px solid ${atoms.colors.yellow}`,
        background: 'transparent',
      },
      focus: {
        color: atoms.colors.yellow,
        border: '1px solid transparent',
      },
      disabled: {
        color: atoms.colors.yellow,
        border: '1px solid transparent',
      },
      checkedDisabled: {
        color: atoms.colors.yellow,
        border: `1px solid ${atoms.colors.yellow}`,
        background: 'transparent',
      },
    },

    topic: {
      idle: {
        color: atoms.colors.content,
      },
      checked: {
        color: atoms.colors.content,
      },
      focus: {
        color: atoms.colors.content,
      },
      disabled: {
        color: color(atoms.colors.content).alpha(0.5).toString(),
      },
      checkedDisabled: {
        color: atoms.colors.content,
      },
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '24px 25px',
      borderRadius: '5px',
      background: atoms.colors.oceanBlue,
      border: 'none',
      boxShadow: 'none',
    },

    title: {
      color: atoms.colors.content,
    },
  };

  const gameCompare = {
    showComments: true,
    compareCard: {
      progressbar: {
        background: atoms.colors.black,
        strokeColor: atoms.colors.black,
      },
    },
    progressbar: {
      separationText: '/',
      textColor: atoms.colors.hint,
      strokeColor: '#9AFB91',
      progressInside: false,
      font: `400 12px/14px ${fonts.display}`,
    },
    announce: {
      font: `400 18px/28px ${fonts.display}`,
      announceIndent: TopicTextWrap,
    },
    compare: {
      caption: {
        color: atoms.colors.content,
        background: atoms.colors.black,
      },
      credits: {
        color: atoms.colors.grey4,
        font: `400 11px/14px ${fonts.display}`,
      },
      text: {
        background: '#f2f2f2',
      },
      draw: {
        backgroundText: `linear-gradient(45deg, ${atoms.colors.yellow}, #e3ac12)`,
      },
    },
    tooltip: {
      theme: 'dark',
      buttonType: 'secondaryDark',
      buttonSize: 'extraSmall',
      scheme: {
        innerColor: '#f2d990',
        outerColor: atoms.colors.primary,
        titleFont: `700 18px/26px ${fonts.display}`,
        textFont: `400 16px/24px ${fonts.display}`,
      },
      info: {
        background: atoms.colors.hint,
        hover: {
          background: atoms.colors.black,
        },
      },
    },
    share: {
      color: atoms.colors.black,
      font: `700 18px/20px ${fonts.display}`,
      marginTop: '50px',
    },
    leaderBoard: {
      button: {
        siteButton: Button,
      },
      card: CompareCard,
      table: {
        borderColor: '#d9d9d9',
        background: atoms.colors.layout,
        activeBackground: '#f2f2f2',
        indent: NegativeMobile,
      },
      header: {
        color: atoms.colors.hint,
        font: `400 11px/14px ${fonts.display}`,
        questionBackground: atoms.colors.grey2,
        questionFill: '#929292',
      },
      count: {
        background: atoms.colors.grey2,
        font: `700 15px/18px ${fonts.text}`,
        activeBackground: atoms.colors.layout,
        activeColor: atoms.colors.primary,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: '#b3b3b3',
        defaultProgress: atoms.colors.primary,
        activeBackground: '#a9a9a9',
        font: `300 12px/17px ${fonts.nuance}`,
      },
      modalWindow: {
        title: `500 18px/30px ${fonts.text}`,
      },
      caption: `400 16px/20px ${fonts.display}`,
      ratings: `700 18px/20px ${fonts.text}`,
    },
    concidences: {
      color: atoms.colors.black,
      font: `400 18px/20px ${fonts.text}`,
      margin: '0 0 15px',
    },
    winner: {
      background: atoms.colors.primary,
      borderRadius: '8px',
      imageTop: '30px',
      strokeColor: atoms.colors.content,
      padding: '30px',
      fillColor: atoms.colors.content,
    },
  };

  const vikontIncut = {
    borderWidth: '1px 0 1px 0',
    padding: '30px 0',
    font: `700 24px/30px ${fonts.display}`,
  };

  const login = {
    popup: {
      button: {
        font: `500 16px/19px ${fonts.text}`,
      },
      content: {
        font: `400 12px/18px ${fonts.text}`,
      },
      background: atoms.colors.white,
    },
    modal: {
      button: {
        color: atoms.colors.content,
      },
    },
  };

  const advTooltip = {
    font: `400 11px/15px ${fonts.text}`,
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.grey2,
      padding: '15px 20px 20px',
      borderRadius: 5,
    },
    title: {
      font: `14px/17px ${fonts.text}`,
      color: atoms.colors.hint,
      marginBottom: '20px',
    },
    feed: {
      columns: 2,
    },
    drum: {
      itemWidth: 270,
      spaceBetween: 10,
      sideIndent: 20,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    layout,
    ads,
    pages,
    controls: {
      dropdown,
      shapka,
      scooter,
      topicFooter,
      topicTags,
      topicContent,
      link,
      button,
      input,
      commentsPreview,
      gallery,
      drum,
      paginatron,
      topicTopline,
      expert,
      author,
      notes,
      mark,
      smartTeaser,
      story,
      storyCard,
      storyWidgetsBaseCard,
      storyWidgetsVariants,
      storyWidgetsRatingCard,
      storyWidgetsHiddenPhoto,
      reactions,
      reactionsEmojiBar,
      gameCompare,
      vikontIncut,
      login,
      advTooltip,
      vikontReadMore,
    },
  }, atoms);
}
