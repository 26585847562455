import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsFetcher } from 'core/fetchers/listPage';
import { topicsFetcher } from 'core/fetchers/topics';

import resolve from 'core/resolver/resolve';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import bindProps from 'core/components/bindProps';
import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import StickyPortal from 'core/components/StickyPortal';
import ColumnLayout from 'core/components/ColumnLayout';
import ListPage from 'core/components/ListPage';
import Feed from 'core/components/Feed';
import Mjolnir from 'core/components/Mjolnir';
import Link from 'core/components/Link';
import H3 from 'core/components/H3';
import Position from 'core/components/GridPosition';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { filterRequiredParams } from 'core/utils/api';

import {
  PageIndent,
  Indent,
} from 'core/components/Wrappers';
import ListDivider from 'site/components/ListDivider';
import SelfPromo from 'core/components/SelfPromo';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';

import CardSmall from 'site/cards/CardSmall';
import {
  CardVertical1,
  CardVertical1S,
} from 'site/cards/CardVertical';
import { CardHorizontalL } from 'site/cards/CardHorizontal';

import ArrowStickRight from 'site/icons/ArrowStickRight';

import { VERTICAL_INDENT } from 'site/constants';


const title = 'Лифт';
const description = 'Проект для стартаперов, которые хотят найти инвесторов и рассказать о себе';

const presenterProps = {
  [DESKTOP]: {
    heroCard: CardHorizontalL,
    card: CardVertical1S,
    interitemSpacing: 30,
  },
  [MOBILE]: {
    card: CardVertical1,
    interitemSpacing: 20,
  },
};


/**
 * TODO:
 * сейчас у нас нет возможности ограничить количество
 * inclues, а карточку без картинки показать в фиде надо,
 * поэтому...
 */
/* eslint-disable react/prop-types */
const CardSmallWithoutImage = props => {
  if (props.content && props.content.relationships) {
    delete props.content.relationships.image;
  }
  return <CardSmall {...props} />;
};
/* eslint-enable react/prop-types */

const NewsCard = bindProps({ card: CardSmallWithoutImage, indent: 10 })(ListDivider);

const listPageLimit = 14;


function LiftPage(props) {
  const {
    rawData,
    news,
    footerContent,
    breakpoint,
    isMobile,
  } = props;

  const Presenter = isMobile ? Feed : Mjolnir;
  const AllNewsWrapper = isMobile ? EmptyWrapper : Position;
  const RazvieCard = isMobile ? NewsCard : CardSmallWithoutImage;

  return (
    <PageBoroda content={footerContent}>
      <ColumnLayout
        rightColumn={(
          <StickyPortal>
            <Indent top={VERTICAL_INDENT} bottom={VERTICAL_INDENT}>
              <H3>Последние новости</H3>
              <Indent bottom={20} />
              <Feed content={news} card={NewsCard} />
              <Indent bottom={30} />
              <SelfPromo />
            </Indent>
          </StickyPortal>
        )}
      >
        <PageIndent>
          <ListPage
            rawData={rawData}
            title={title}
            description={description}
            limit={listPageLimit}
          >
            {({ content }) => {
              const first = content.slice(0, 3);
              const second = content.slice(3, 6);
              const third = content.slice(6, 10);
              const fourth = content.slice(10);
              return (
                <Fragment>
                  <Feed
                    content={first}
                    columns={first.length}
                    card={presenterProps[breakpoint].card}
                    interitemSpacing={presenterProps[breakpoint].interitemSpacing}
                  />
                  <ListDivider indent={20} />
                  <Feed
                    card={RazvieCard}
                    columns={2}
                    interitemSpacing={presenterProps[breakpoint].interitemSpacing}
                    grid
                    tag='razvitie'
                    limit={4}
                  />
                  <Indent bottom={15} />
                  <AllNewsWrapper right>
                    <Link to='/label/razvitie' type='primary'>
                      <H3 is='span'>Все новости</H3>
                      <ArrowStickRight style={{ marginLeft: 7 }} />
                    </Link>
                  </AllNewsWrapper>
                  <ListDivider indent={20} />
                  <Feed
                    content={second}
                    columns={second.length}
                    card={presenterProps[breakpoint].card}
                    interitemSpacing={presenterProps[breakpoint].interitemSpacing}
                  />
                  <Indent bottom={presenterProps[breakpoint].interitemSpacing} />
                  <Presenter
                    content={third}
                    {...presenterProps[breakpoint]}
                  />
                  <Indent bottom={presenterProps[breakpoint].interitemSpacing} />
                  <Presenter
                    content={fourth}
                    {...presenterProps[breakpoint]}
                  />
                  <Indent bottom={40} />
                </Fragment>
              );
            }}
          </ListPage>
        </PageIndent>
      </ColumnLayout>
    </PageBoroda>
  );
}

LiftPage.propTypes = {
  rawData: PropTypes.object,
  news: PropTypes.array,
  footerContent: PropTypes.array,
  breakpoint: breakpointPropTypes(),
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  rawData: rawTopicsFetcher({
    limit: listPageLimit,
    include: 'image',
    fields: filterRequiredParams([CardSmall, CardVertical1, CardHorizontalL], 'fields'),
    rubric: 'selfie',
  }),

  news: topicsFetcher({
    card: NewsCard,
    limit: 6,
    topic_type: 'news',
  }),

  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(LiftPage));
